'use client'
import React, { useState, useEffect, useRef } from 'react'
import { useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { AiOutlineLayout, AiFillHeart, AiOutlineHeart } from "react-icons/ai"
import { BiSolidBed, BiSolidBath } from "react-icons/bi"
import tailwindConfig from '@tailwind.config'
import { sendLike } from '@services/sendLike'
import CurrencySymbol from '@components/CurrencySymbol'
import LinkComponent from './LinkComponent'
import { IoLocationOutline } from "react-icons/io5";
import parse from 'html-react-parser';
import StarRating from '@components/StarRating'

const TestamonialCard = ({ testamonial, imageWidth = 300, imageHeight = 200, dictionary, lang }) => {


    return (
        <div className='w-full border-[1px] border-gray-200 rounded-xl overflow-hidden shadow-sm shadow-maincolor/30 hover:shadow-maincolor/70 duration-300'>
            <div className='text-gray-500 h-[100px] italic flex flex-row p-4'>
                <span className='line-clamp-4'>{parse(`${testamonial.content}`)}</span>
            </div>
            <div className='w-full flex flex-col'>
                <div className='h-[60px]'></div>
                <div className='w-full h-[120px] relative flex flex-col justify-end bg-gradient-to-br from-maincolor to-[#40382E] items-center p-4'>
                    <div className='top-[-40px] absolute rounded-full bg-white p-[2px]'>
                        <div className='relative w-[80px] aspect-square rounded-full bg-light overflow-hidden'>
                            <Image
                                className='object-cover hover:shadow-xl'
                                src={testamonial.cover}
                                fill={true}
                                alt={'BullMark Properties Logo'}
                            />
                        </div>
                    </div>
                    <span className='text-light'>{testamonial.name}</span>
                    <StarRating rating={parseFloat(testamonial.rating)} />
                </div>
            </div>
            
        </div>
    )
}

export default TestamonialCard