export const searchTypes = {
    'en': [{
        title: 'Buy',
        heroTitle: 'Buy',
        value: 'sale'
    },
    {
        title: 'Rent',
        heroTitle: 'Rent',
        value: 'rent'
    }
],
    'th': [{
        title: 'ซื้อ',
        heroTitle: 'Buy',
        value: 'sale'
    },
    {
        title: 'เช่า',
        heroTitle: 'Rent',
        value: 'rent'
    }
],
    'cn': [{
        title: '买',
        heroTitle: 'Buy',
        value: 'sale'
    },
    {
        title: '租',
        heroTitle: 'Rent',
        value: 'rent'
    }
]
}