'use client'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/navigation'
import { RadioGroup } from '@headlessui/react'

import { updateSearchParams, getSearchParams, removeSearchParam } from "@utils";
import { saleTypes, categoryTypes, serviceHouseRoomTypes, serviceCondoRoomTypes } from '@constants'

const roomTypes = serviceCondoRoomTypes

const HomeRoomFilter = ({ onChange, title, lang, dictionary }) => {
    const router = useRouter();
    let [room, setRoom] = useState(roomTypes[lang][0])
    let [addChecked, setAddChecked] = useState(roomTypes[lang][0].value)


    const handleUpdateParams = (e) => {
        // let newPathName
        // if (e.value == 0) {
        //     newPathName = removeSearchParam(title);
        // } else {
        //     newPathName = updateSearchParams(title, e.value);
        // }

        // router.push(newPathName);
        // router.refresh()
    };

    useEffect(() => {
        // const roomValue = getSearchParams('room')
        // setAddChecked(roomValue)
        // const newValue = roomTypes[lang].find(obj => obj.value === addChecked);

        // if (newValue != undefined) {
        //     setRoom(newValue)
        // } else {
        //     setRoom(roomTypes[lang][0])
        // }

    }, [handleUpdateParams])

    return (
        <div className="w-full z-1">
            <span className='text-maincolor text-md pb-2'>{dictionary.numberOfBedrooms}:</span>
            <div className="w-full z-1">
                <RadioGroup value={room} onChange={(value) => {
                    // console.log(value)
                    setRoom(value)
                    setAddChecked(value.value)
                    // handleUpdateParams(value)
                    onChange(value)
                }}
                    className='z-1 w-full flex flex-row mt-2 rounded-lg overflow-hidden'
                >
                    {roomTypes[lang].map((roomType) => (
                        <RadioGroup.Option
                            key={roomType.value}
                            value={roomType}
                            checked={roomType.value == addChecked}
                            className={({ active, checked }) =>
                                `${active
                                    ? ''
                                    : ''
                                }
                                    ${checked ? 'bg-maincolor border-third text-light' : 'bg-light border-maincolor'
                                }
                                w-full rounded-full border-[1px] z-1 flex cursor-pointer px-2 py-2 focus:outline-none`
                            }
                        >
                            {({ active, checked }) => (
                                <>
                                    <div className="flex w-full items-center justify-center">
                                        <div className="flex items-center">
                                            <div className="text-sm text-center">
                                                <RadioGroup.Label
                                                    as="p"
                                                    className={`font-medium w-full  ${checked ? 'text-light' : 'text-maincolor'
                                                        }`}
                                                >
                                                    {roomType.title}
                                                </RadioGroup.Label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </RadioGroup>
            </div>
        </div>
    )
}

export default HomeRoomFilter