'use client'
import React, { useEffect, useState } from 'react'
import TestamonialCard from '@components/TestamonialCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const Testamonials = ({ testamonials, dictionary, lang }) => {
    return (
        <section className='2xl:w-10/12 max-w-[1600px] py-8 px-4 mx-auto'>
            <h2 className='text-maincolor font-bold text-xl mb-2'>Testamonials</h2>
            <div className='sm:block hidden pb-1'>
                <Swiper
                    slidesPerView={4}
                    spaceBetween={16}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Navigation]}
                    className=''
                >
                    {testamonials.map((testamonial, index) => (
                        <SwiperSlide className='pb-1' key={index} >
                            <TestamonialCard testamonial={testamonial} dictionary={dictionary.card} lang={lang} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className='sm:hidden block'>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1.5}
                        
                    >
                        {testamonials.map((testamonial, index) => (
                            <SwiperSlide className='pb-1' key={index}>
                                <TestamonialCard testamonial={testamonial} dictionary={dictionary.card} lang={lang} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
        </section>
    )
}

export default Testamonials