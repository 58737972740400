import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import LinkComponent from './LinkComponent'
// import '../public/images/bangkok.png'

const LargeSwiperImage = ({ title, imageSrc, type, location }) => {
    return (
        <LinkComponent href={`/properties?${type}=${location}`} className='relative w-full'>
            <div className='w-full h-[220px] relative overflow-hidden rounded-xl'>
                <Image
                    src={imageSrc}
                    fill={true}
                    className='object-cover'
                    alt={title}
                // style={{ objectFit: "contain" }}
                />
                <div className='absolute bottom-0 left-0 right-0 rounded-b-md flex justify-start items-end h-[60px] p-4 bg-gradient-to-t from-maincolor/60 to-none overflow-hidden'>
                    <span className='text-light text-xl z-10 px-1 '>{title}</span>
                </div>
            </div>
        </LinkComponent>
    )
}

export default LargeSwiperImage