'use client'
import React, { Component} from 'react';
// import { FacebookProvider, Page } from 'react-facebook';
import Script from 'next/script';

const FacebookProvider = async ({ children }) => {
  return (
    <>  
      <Script
    />
            {children}
    </>
  )
}

export default FacebookProvider